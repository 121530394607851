<template>
  <div class="page_user--profile">
    <div class="inner-wrap">
      <div class="basic_info">
        <div class="item">
          <div class="label" v-t="`field.system_user.username`"></div>
          <div class="value">{{ user_info.name }}</div>
        </div>
      </div>
      <div class="content-wrap">
        <div class="sidebar">
          <div class="item"
            v-for="item in sidebar.items"
            :key="item"
            v-t="`label.profile_sidebar.${item}`"
            :class="{ 'selected': item == sidebar.selected }"
            @click="selectSidebarItem(item)"
          />
        </div>
        <div class="divider"></div>
        <div class="content">
          <!-- access log -->
          <div class="access_log_table" v-if="sidebar.selected == 'user_access_log'">
            <Datatable
              :unikey="'user_access_logs'"
              :fields="['id','remote_ip','msg','is_login_success','login_dt']"
              :rows="access_logs"
              :config="field_setting"
            />
            <Pagination :max="pagination.max" @page-change="pageChanged"/>
          </div>
          <!-- change password -->
          <div class="change_password_wrap" v-else-if="sidebar.selected == 'change_password'">
            <div class="item">
              <div class="label" v-t="`field.system_user.current_password`"></div>
              <InputBox :setup="{ type: 'password' }" inputType="password" v-model="input.current_password" @input="error.current_password=''" :error="error.current_password" :viewPassword="true"/>
              <div class="error" v-if="error.current_password" v-t="`validation.${error.current_password}`"/>
            </div>
            <div class="item">
              <div class="label" v-t="`field.system_user.new_password`"></div>
              <InputBox :setup="{ type: 'password' }" inputType="password" v-model="input.password" @input="error.password=''" :error="error.password" :viewPassword="true"/>
              <div class="error" v-if="error.password" v-t="`validation.${error.password}`"/>
            </div>
            <div class="item">
              <div class="label" v-t="`field.system_user.confirm_new_password`"></div>
              <InputBox :setup="{ type: 'password' }" v-model="input.confirm_password" inputType="password" @input="error.confirm_password=''" :error="error.confirm_password" :viewPassword="true"/>
              <div class="error" v-if="error.confirm_password" v-t="`validation.${error.confirm_password}`"/>
            </div>
            <div class="action-btn">
              <EvButton @click="changePassword">{{ $t("button.confirm_change_password") }}</EvButton>
            </div>
          </div>
          <!-- end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Datatable from "@/components/widgets/Datatable/Datatable";
import Pagination from "@/components/widgets/Datatable/modules/Pagination";

export default {
  components: {
    Datatable,
    Pagination
  },
  async beforeMount() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$axios.get(`user_access_logs`,{
        params: {
          user_id: this.user_info.id,
          size: this.pagination.perPage,
          page: this.pagination.current > 0 ? this.pagination.current - 1 : 0,
          sort: [{
            field:"id",
            order:"desc"
          },]
        }
      }).then(res=>{
        let data = res?.data?.response?.dataset;
        if(data) {
          this.access_logs = data.content;
          this.pagination.max = data.totalPages;
          // prevent current > max
          if(this.pagination.current > this.pagination.max) {
            this.pagination.current = this.pagination.max;
          }
        }
      });
    },
    pageChanged(page) {
      this.pagination.current = page;
      this.fetchData();
    },
    selectSidebarItem(item) {
      this.sidebar.selected = item;
      this.$forceUpdate();
    },
    validate() {
      if(this.input.password?.length == 0 || this.input.confirm_password?.length == 0 || this.input.current_password?.length == 0) {
        if(this.input.current_password?.length == 0) {
          this.error.current_password = "REQUIRED";
        }
        if(this.input.password?.length == 0) {
          this.error.password = "REQUIRED";
        }
        if(this.input.confirm_password?.length == 0) {
          this.error.confirm_password = "REQUIRED";
        }
        return false;
      }
      if(this.input.password != this.input.confirm_password) {
        this.error.confirm_password = "CONFIRM_PASSWORD_NOT_MATCH";
        return false;
      }
      return true;
    },
    async changePassword() {
      if(!this.validate()) return;
      //
      this.needLoading(true);
      try{
        await this.$axios.put("/system_user/change_password",{
          ...this.input
        }).then(res=>{
          this.$notify({
            type: "success",
            msg: this.$t("message.change_password_successful")
          });
        }).catch(err=>{
          if(err?.response?.data?.errors) {
            err?.response?.data?.errors?.map(code=>{
              this.$notify({
                title: `${err?.response?.status}`,
                msg: this.$t(`error.code.${code}`),
                type: "error"
              });
            });
            // this.$store.commit("control/setPopup", {show: true, messages: err.response.data.errors});
          }else {
            this.$notify({
              title: `${err.response.status}`,
              msg: "error",
              type: "error"
            });
          }
        });
      }catch{
      }finally{
        this.needLoading(false);
        let vm = this;
        setTimeout(()=>{
          vm.$store.dispatch("user/fetchPermissions");
        }, 500);
      }
    }
  },
  computed: {
    ...mapState({
      user_info: state => state.user.user_info,
    })
  },
  data() {
    return {
      access_logs: [],
      input: {},
      field_setting: {
        is_login_success: { type: "boolean" },
        login_dt: { type: "datetime", props: { sourceFormat: "x" } }
      },
      pagination: {
        perPage: 10,
        total: 0,
        current: 1,
        item: 0,
        max: 0
      },
      sidebar: {
        selected: "change_password",
        items: ["change_password"]
      },
      error: {
        current_password: "",
        password: "",
        confirm_password: "",
      }
    }
  }
}
</script>

<style lang="scss">
.page_user--profile{
  display: flex;
  justify-content: center;
  height: 100%;
  .inner-wrap{
    width: 90%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    .content-wrap{
      display: flex;
      margin-top: 50px;
      flex-grow: 1;
      overflow-y: auto;
      .sidebar{
        width: 170px;
        .item{
          height: 40px;
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all .2s linear;
          &.selected{
            background: #ddd;
          }
          &:hover:not(.selected){
            background: #f2f2f2;
          }
        }
      }
      .divider{
        width: 2px;
        background: #555;
        opacity: .1;
        margin: 20px 20px;
      }
      .content{
        margin-left: 20px;
        flex-grow: 1;
        .change_password_wrap{
          .item{
            margin-bottom: 10px;
            .label{
              margin-bottom: 5px;
            }
            .error{
              margin-top: 2px;
              color: red;
              font-size: 13px;
            }
          }
          .action-btn{
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            .module--ev_button{
              height: 40px!important;
              width: 150px!important;
            }
          }
        }
      }
    }
  //   display: flex;
  //   .basic_info{
  //     width: 40%;
  //     display: flex;
  //     flex-direction: column;
  //     .item{
  //       display: flex;
  //       .label{
  //         width: 70px;
  //       }
  //     }
  //   }
  //   .divider{
  //     width: 2px;
  //     background: #555;
  //     opacity: .1;
  //     margin: 20px 30px;
  //   }
    .access_log_table{
      flex-grow: 1;
      .title{
        margin-bottom: 10px;
      }
      .widget--datatable{
        table{
          width: initial;
        }
      }
    }
  }
}
</style>